import React, { useContext, useEffect, useRef } from 'react';
import { IntlProvider } from 'react-intl';
import { RouterProvider } from 'react-router-dom';
import { UserClaimsProvider } from './components/Authentication/UserClaimsProvider';
import { MsalProvider, useMsal } from '@azure/msal-react';
import UserClaimsConsumer from './components/Authentication/UserClaimsConsumer';
import usr from './utils/userUtils/UserActions';
import { ConfigContext } from './components/Authentication/ConfigContext';
import {
  AccountInfo,
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { authRouter, notAuthRouter } from './RoutesConfig';
import { Pages } from './pages/PageImports';
import { AuthenticatedFilter } from './components/Authentication/AuthenticatedFilter';
import { UnauthenticatedFilter } from './components/Authentication/UnauthenticatedFilter';
import { silentRequest } from './components/Authentication/ConfigProvider';
import '@piranacon/lexxic-shared/dist/cjs/index.css';
import './index.css';
import LoadingDiv from './components/LoadingDiv/LoadingDiv';
const App = () => {
  const { accounts } = useMsal();
  const [locale, setLocale] = React.useState('en');
  const msalInitialised = useRef<boolean>(false);
  const { msalConfig } = useContext(ConfigContext);
  const [msalInstance, setMsalInstance] =
    React.useState<PublicClientApplication | null>(null);

  useEffect(() => {
    if (msalConfig && !msalInitialised.current) {
      msalInitialised.current = true;

      const msalInstance = new PublicClientApplication(msalConfig);

      setMsalInstance(msalInstance);

      msalInstance.initialize().then(async () => {
        // Default to using the first account if no account is active on page load
        if (msalInstance.getActiveAccount()) {
          renewTokenSilently(msalInstance, msalInstance.getActiveAccount()!);
          return;
        } else if (
          !msalInstance.getActiveAccount() &&
          msalInstance.getAllAccounts().length > 0
        ) {
          renewTokenSilently(msalInstance, msalInstance.getAllAccounts()[0]!);
          msalInstance.setActiveAccount(msalInstance!.getAllAccounts()[0]);
        }

        // Optional - This will update account state if a user signs in from another tab or window
        msalInstance.enableAccountStorageEvents();

        msalInstance.addEventCallback((event: any) => {
          if (
            event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS
          ) {
            const account = event.payload.account;
            msalInstance.setActiveAccount(account);
          }
        });
      });
    }
  }, [msalConfig, accounts]);

  const userClaimsCheck = () => {
    return (
      <>
        <IntlProvider messages={{}} locale={locale} defaultLocale="en">
          <UserClaimsConsumer>
            {(userClaims) => {
              if (!userClaims) {
                // No user claims, show the non-authenticated router
                return <RouterProvider router={notAuthRouter} />;
              } else if (!userClaims.loaded) {
                // User claims are loading, show the loading screen
                return (
                 <LoadingDiv />
                );
              } else if (usr.userHasAgreedToPolicy(userClaims.user)) {
                // User has agreed to the policy, show the authenticated router
                return <RouterProvider router={authRouter} />;
              } else {
                // User has not agreed to the policy, show the consent view
                return <Pages.ConsentView displayAgreement={true} />;
              }
            }}
          </UserClaimsConsumer>
        </IntlProvider>
      </>
    );
  };

  const renewTokenSilently = async (
    instance: PublicClientApplication,
    account: AccountInfo
  ) => {
    console.log(instance);
    if (!instance || msalInitialised.current === false) {
      return;
    }

    let request = silentRequest(account);
    const tokenResponse = await instance
      .acquireTokenSilent(request)
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          await instance.acquireTokenRedirect(request);
        }
      });
  };

  return (
    msalInstance && (
      <MsalProvider instance={msalInstance}>
        <IntlProvider messages={{}} locale={locale} defaultLocale="en">
          <UserClaimsProvider>
            <AuthenticatedFilter>{userClaimsCheck()}</AuthenticatedFilter>
            <UnauthenticatedFilter>
              <RouterProvider router={notAuthRouter} />
            </UnauthenticatedFilter>
          </UserClaimsProvider>
        </IntlProvider>
      </MsalProvider>
    )
  );
};

export default App;
